import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue,BootstrapVueIcons} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import VueYoutube from 'vue-youtube'

import Spinner from 'vue-spinkit'



Vue.component('Spinner', Spinner)


import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
 

import InputColorPicker from "vue-native-color-picker";

import excel from 'vue-excel-export'
 
Vue.use(excel)


import ElementUI from 'element-ui';


import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

import "@/assets/scss/app.scss";
import '@/assets/elementuicss/index.css';




Vue.config.productionTip = true
Vue.use(ElementUI);
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(BootstrapVueIcons)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueFileAgent);
Vue.use(VueFileAgentStyles);
Vue.use(InputColorPicker);
locale.use(lang)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))

Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
